<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>

        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Lifetag Name"
                  class="mr-btm-minus"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingCurd"
                >
                  {{ headercontent.title }}
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingCurd"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddLifetag",
  components: { PageHeader },
  data: () => ({
    valid: true,
    name: "",
    headercontent: {
      title: "Add Lifetag",
    },
    nameRules: [(v) => !!v || "Life Tag Name is required"],
    id: null,
  }),
  methods: {
    ...mapActions(["addLifeTag", "fetchLifeTagById", "updateLifeTag"]),
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          name: this.name,
        };
        if (this.id) {
          this.updateLifeTag({ id: this.id, data });
        } else {
          this.addLifeTag(data);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "currentLifeTag"]),
  },

  async created() {
    if (this.$route.params.id != undefined) {
      await this.fetchLifeTagById(this.$route.params.id);
      this.name = this.currentLifeTag.name;
      this.id = this.currentLifeTag.id;
      this.headercontent.title = "Update Life Tag";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}
.uploadedImage {
  width: 150px;
  padding: 20px;
}
.imagebox {
  display: flex;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.rmimagebox {
  position: relative;
  width: 200px;
    img{
    width: 100%;
  }
  .v-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
